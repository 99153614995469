<template>
    <b-container>
        <b-card class="mb-0">
            <div class="border rounded p-1">
            <b-row cols="12" class="px-1">
                <b-col sm="12" md="5" class="d-flex align-items-center search-head-title px-0">
                    <svg
                    class="mr-1"
                    style="width: 50px"
                    aria-hidden="true"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0,0h40v40H0V0z"></path>
                    <g>
                    <path
                        d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                        fill="#EA4335"
                    ></path>
                    <path
                        d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                        fill="#FBBC04"
                    ></path>
                    <path
                        d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                        fill="#4285F4"
                    ></path>
                    <path
                        d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                        fill="#34A853"
                    ></path>
                    </g>
                </svg>
                    <h3 class="function-title">App Localization Checker</h3>
                </b-col>
    
                <b-col sm="12" md="7" class="mt-1 mt-md-2 mt-lg-2 mt-xl-2 mx-0 px-0">
                <b-form @submit.prevent="onSubmit" class="d-flex localization-form">
                        <b-form-group class="col-md-9">
                            <b-form-input
                                type="text"
                                class="form-control"
                                placeholder="App ID"
                                autofocus
                                id="search-input"
                                v-model="search"
                            />
                            <small ref="searchError" class="text-danger"></small>
                        </b-form-group>
                        <span class="col-md-3">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-success"
                            :class="`circle w-100 ${isDisable&&'cursor-not-allowed'}`"
                            type="submit"
                            v-b-tooltip.hover
                            title="Search"
                            :disabled="isDisable"
                            >
                                <feather-icon icon="SearchIcon" size="14" />
                            </b-button>
                        </span>
                    </b-form>
                </b-col>
            </b-row>
           
            </div>
        </b-card>
        <br>
        <b-card>
            <h2 class="text-center w-100 m-0">Check app languages from more than 78 locations around the world.</h2>
        </b-card>
        <b-card v-if="apps.length > 0">
            <div class="mr-1 ml-0 " v-for="(app,key) in apps" :key="key">
                <span class="d-flex">
                    <img class="rounded" :src="app.icon" width="50px" height="50px">
                    <span class="ml-1">
                        <span>{{ app.title }}</span><br>
                        <span>
                            <span v-for="(lang,langKey) in app.langs" :key="langKey">
                                <small><b>{{ lang }} </b></small>
                                <span v-if="langKey !== app.langs.length - 1"> , </span>
                            </span>
                        </span>
                    </span>
                </span>
                <hr v-if="key !== apps.length - 1">
            </div>
            <hr v-if="progress">
            <div v-if="progress" class="d-flex">
                <b-skeleton class="mr-1" type="button" height="50px" width="50px"></b-skeleton>
                <span class="w-100" style="margin-top: 5px;">
                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                    <b-skeleton animation="wave" width="20%"></b-skeleton>
                </span>
            </div>
        </b-card>
    </b-container>
</template>
  
<script>
  import Ripple from "vue-ripple-directive";
  import { connectApi } from "@/helper/connectApi";
  import { 
    BContainer,
    BCard, 
    BCol,
    BRow, 
    BButton, 
    BFormGroup, 
    BForm, 
    BFormInput,
    VBTooltip,
    VBHover,
    BSkeleton,
    BSkeletonImg
    } from "bootstrap-vue";
  import langsJson from "../../assets/jsons/langs.json" 
  export default {
    components: {
        BContainer,
        BCard,
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BForm,
        BFormInput,
        VBTooltip,
        VBHover,
        BSkeleton,
        BSkeletonImg
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            progress:false,
            search: null,
            langs:null,
            apps:[],
            isDisable:false,
        };
    },
    mounted() {
        this.getLangs()
    },
    methods: {
        getLangs(){
            this.langs = langsJson.langs
        },
        async onSubmit() {
            if (this.search) {
                this.$refs.searchError.textContent = "";
                this.progress = true;
                // this.isDisable = true;
                this.apps = [];
                const response = await  connectApi(`https://scx.clicklab.app/api/apps/${this.search}/?fullDetail=true&country=ma&lang=en-US`);

                if(response == undefined){
                    console.clear();
                    this.$refs.searchError.textContent = "This App not found";
                }

                if(response){
                    if(response.status == 200){
                        for(let i = 0 ; i <= this.langs.length ; i++){                       
                            const response = connectApi(`https://scx.clicklab.app/api/apps/${this.search}/?fullDetail=true&country=ma&lang=${this.langs[i]?.value}`);
                            console.clear();
                            response.then(async (response) => {
                                const { status } = await response.data;
                                if (status === "Unauthorized") {
                                    deleteAccessTokenCookie();
                                    router.push({ name: "auth-login" });
                                    return;
                                }
                                const data = response.data;
                                return data;
                            }).then((data) => {
                                const existingAppIndex = this.apps.findIndex(app => app.title === data.title);

                                if (existingAppIndex !== -1) {
                                    this.apps[existingAppIndex].langs.push(this.langs[i]?.value);
                                } else {
                                    this.apps.push({ title: data.title, icon: data.icon, langs: [this.langs[i]?.value] });
                                }
        
                                if(i == (this.langs.length - 1)){
                                    this.progress = false;
                                    this.isDisable = false;
                                }
                            }).catch((error) => {
                                this.progress = false;
                                this.isDisable = false;
                                console.log(error);
                            });
                        }  
                    }else{
                        this.$refs.searchError.textContent = "This App not found";
                    }
                }
                
                

                               

            } else {
                this.$refs.searchError.textContent = "The Search Field Is Required!";
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
@media only screen and (max-width: 740px) {
  .localization-form{
    flex-direction: column;
  }
  .cursor-not-allowed{
    cursor: not-allowed !important;
  }
}
</style>